import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSpecialtyService } from '../../services/base';
import { TypeaheadComponent } from '../../shared/template/typeahead/typeahead.component';
import { BrowseSpecialtiesComponent } from '../../browse-specialties/browse-specialties.component';
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  imports: [TypeaheadComponent, BrowseSpecialtiesComponent],
  standalone: true
})

export class SearchBarComponent implements AfterContentChecked {

  public isModalOpen = false;

  @Output() public submitSearchEvent = new EventEmitter();

  public get currentlySelected(): number[] {
    return this.specialtyService.getSelectedArray();
  }

  constructor(private specialtyService: BaseSpecialtyService, private router: Router, private ref: ChangeDetectorRef) {}

  public ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  public search($event): void {
    let converted = [];
    if ($event.term === '') {
      this.onSubmitSearch();
      if(this.specialtyService.getSelectedSpecs()) {
        converted = this.specialtyService.getSelectedSpecs().map( specialty => {
          return specialty.nid;
        });
      }
      const route = this.router.url;
      if (route.includes('map')) {
        this.router.navigate(['/search/map'], { queryParamsHandling: "merge", queryParams: { 'spec': converted.join(','), } });
      } else {
        this.router.navigate(['/search/list'], { queryParamsHandling: "merge", queryParams: { 'spec': converted.join(','), } });
      }
    } else {
      this.onSubmitSearch();
      this.router.navigate(['program', $event.term]);
    }
  }

  public onSubmitSearch() {
    this.submitSearchEvent.emit({ submitted: true });
  }

  public openSpecialtiesModal() {
    this.isModalOpen = !this.isModalOpen;
  }

  public afterModalClose(event: { modal: boolean }) {
    this.isModalOpen = event.modal;
  }
}
