<div class="freida-typeahead-container" data-test="typeahead-container" [ngClass]="{'freida-typeahead-container-opened': startTyping || model.input && model.input.length > 0 }">
  <div class="freida-typeahead-container__main">
    <div class="freida-typeahead-container__main__search" #typeaheadSubmitButton
         data-test="typeahead-submit-button"
         [ngClass]="buttonIsActive() ? 'freida-typeahead-container__main__search__active' : ''" (click)="onButtonClick()">
    <ama-icon [color]="'gray'" [size]="'1.5em'" [name]="'search'"></ama-icon>
  </div>
    <input class="freida-typeahead-container__main__input"
      data-test="typeahead-container-input"
      tabindex="0" aria-label="Search by specialty or program id"
      [placeholder]="PLACEHOLDER_DEFAULT"
      (keydown.enter)="enterPressed($event)"
      (keydown.arrowDown)="traverse(1)"
      (keydown.arrowUp)="traverse(-1)"
      type="text" name="typeaheadInput"
      (focus)="inputAction('focus')"
      (blur)="inputAction('blur')"
      [(ngModel)]="model.input"
      (input)="initSearch()"
      autocomplete="off">
  </div>
  <!-- IF SEARCH TERM IS A SPECIALTY -->
  <div class="freida-typeahead-container__results" role="listbox" *ngIf="filterTerm(model.input) && !isProgramId && currentlySelected.length < 500">
    <ul class="freida-typeahead-container__results__list" data-test="typeahead-container-results-list" #scrollframe>
      <li class="freida-typeahead-item" *ngFor="let res of results$ | async"
        (click)="navigateToSearch(res)" [ngClass]="{'freida-typeahead-item-selected': res.idx === accSelection}"
        tabindex="-1" role="option" aria-checked="res.idx === accSelection" #item>
        {{res.name}}
      </li>
    </ul>
  </div>
  <!-- IF SEARCH TERM IS A PROGRAM ID -->
  <div class="freida-typeahead-item" #scrollframe *ngIf="isProgramId"></div>
  <div class="freida-typeahead-container__results" role="listbox" *ngIf="filterTerm(model.input) && isProgramId">
    <ul class="freida-typeahead-container__results__list" data-test="typeahead-container-results-list" *ngIf="results$ | async as res">
      <li *ngIf="res.programName" (click)="navigateToProgram(res.programNumber)"
        class="freida-typeahead-item" [ngClass]="{'freida-typeahead-item-selected': res.idx === accSelection}">
        {{res.programNumber}} - {{res.programName}}
      </li>
    </ul>
  </div>
  <!-- FEEDBACK/ERRORS -->
  <div class="freida-typeahead-container__helper-text" data-test="freida-typeahead-dropdown" *ngIf="isLoading">Loading...</div>
  <div class="freida-typeahead-container__helper-text" data-test="freida-typeahead-dropdown" *ngIf="programError && isProgramId && model.input?.length > 3">No program was found</div>
  <div class="freida-typeahead-container__helper-text" data-test="freida-typeahead-dropdown" *ngIf="!isProgramId && model?.input?.length > 3 && accSelectionCount < 1">No data found for "{{model.input}}"</div>
  <div class="freida-typeahead-container__helper-text" data-test="freida-typeahead-dropdown" *ngIf="startTyping && !model.input || startTyping && model.input?.length < 3">Type to search</div>
</div>
