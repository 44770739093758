import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  share,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { ProgramModel, SpecialtyModel } from '../../../models';
import { BaseSpecialtyService } from '../../../services/base';
import { ProgramService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AmaIconComponent } from 'src/lib/components/icon/icon.component';
@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, FormsModule, AmaIconComponent],
  standalone: true
})
export class TypeaheadComponent implements OnInit {
  public readonly MAX_SPECIALTIES_SELECTED = 500;
  public readonly PLACEHOLDER_DEFAULT = 'Search by Specialty or Program ID';
  public readonly PLACEHOLDER_LIMIT_REACHED = 'Maximum number of Specialties Selected';
  // typeahead input and search term
  public model = {input: null};
  public typeaheadInput = new UntypedFormControl();
  // Getter for selected specialties
  public get currentlySelected(): number[] {
    return this.specialtyService.getSelectedArray();
  }
  // OUTPUTS
  @Output() public selectChange = new EventEmitter<any>();
  @Output() public searchSubmit = new EventEmitter<any>();
  // Observable to stream search term
  public searchValue$ = new BehaviorSubject('');
  public results$: Observable<SpecialtyModel[] | ProgramModel>;
  public isProgramId = false;
  // the stream for selected specialties/chips
  public chips$ = this.specialtyService.getSelectedSpecialties().pipe(
    map((nidArray: string[]) => nidArray.length > 0 ? this.specialtyService.convertNidToSpecialty(nidArray) : [])
  );
  // Flag for when a user starts typing
  public startTyping = false;
  public programError = false;
  public isLoading = false;
  // Accessibility
  public accSelectionCount = null;
  public accSelection = null;
  public accMap = [];
  public typeAheadChanged = false;
  @ViewChild('scrollframe') public scrollFrame: ElementRef;
  @ViewChildren('item') public itemElements: QueryList<any>;
  private scrollContainer: any;
  private scrollAmount = 0;

  constructor(
    public specialtyService: BaseSpecialtyService,
    private programService: ProgramService,
    public router: Router) {}

  public initSearch() {
    this.searchValue$.next(this.model.input);
  }

  public getSelectedSpecialties() {
    return this.specialtyService.getSelectedSpecs();
  }

  public ngOnInit() {
    this.chips$ = this.specialtyService.getSelectedSpecs();
    this.results$ = this.searchValue$.pipe(
      // only allow new search terms to come in every 300ms
      debounceTime(50),
      distinctUntilChanged(),
      // Filter results based on term - returns either specialties or a program
      filter(term => this.filterTerm(term)),
      switchMap((searchText: any) => {
        this.resetAccessibility();
        // determine if this is an 'm' program id
        const firstChar = searchText.substr(0,1);
        const restOfText = searchText.substr(1);
        if (firstChar.toLowerCase() == 'm' && parseInt(restOfText, 10)  ) {
          return this.getPrograms(searchText);
        }
        // determine if the input text is a string or int, then route data appropriately
        return +searchText !== parseInt(searchText, 10) ? this.getSpecialties(searchText) : this.getPrograms(searchText);
      }),
      tap(() => {
        if (!this.isProgramId) {
          this.scrollContainer = this.scrollFrame.nativeElement;
        }
      }),
      // share() will allow for multicasting to many subscribers with clean up built in
      share()
    );
  }
  // if search term is a valid specialty, return specialties that match the term
  public getSpecialties(filterTerm: string): Observable<SpecialtyModel[]> {
    this.isProgramId = false;
    const term = filterTerm.toLowerCase();
    return this.specialtyService.getAllSpecialties().pipe(
      map((specialtyArray: SpecialtyModel[]) => {
        // filter by matching the term from typeahead
        const filteredByTerm = specialtyArray.filter((spec) => spec.name.toLowerCase().indexOf(term) > -1 && this.model.input);
        // sync with accessibilty logic
        const finalized = filteredByTerm.map(program => {
          this.accSelectionCount === null ? this.accSelectionCount = 0 : this.accSelectionCount++;
          program['idx'] = this.accSelectionCount;
          this.accMap.push(program);
          return program;
        });
        return finalized;
      })
    );
  }
  // if term is a valid program number, return the program
  public getPrograms(programNum: string): Observable<ProgramModel> {
    if (programNum) {
      this.isProgramId = true;
      this.isLoading = true;
      return this.programService.getProgram(programNum).pipe(
        // intercepts any error that will break the observable stream
        catchError(this.handleErrors),
        map(program => {
          // if null, lets throw the error in the view
          if (program) {
            this.programError = false;
            this.accSelectionCount === null ? this.accSelectionCount = 0 : this.accSelectionCount++;
            program['idx'] = this.accSelectionCount;
            this.accMap.push(program);
          } else {
            this.programError = true;
          }
          this.isLoading = false;
          return program;
        })
      );
    }
  }
  // will only pass terms that meet the criteria for specialty or program ID
  public filterTerm(term: string) {
    if (+term !== parseInt(term, 10)) {
      return term !== null && term.length > 2 ? true : false;
    } else if (term === '' || term === ' ') {
      return true;
    } else {
      return term !== null && term.length === 10 ? true : false;
    }
  }

  public onButtonClick() {
    if (this.buttonIsActive()) {
      this.searchSubmit.emit({ event: 'click', term: this.model.input || '' });
      this.model.input = '';
      this.typeAheadChanged = false;
    }
  }

  public buttonIsActive() {
    if ((( this.typeAheadChanged ) || (+this.model.input && this.model.input.length === 10 && !this.programError))) {
      return true;
    } else {
      return false;
    }
  }

  public inputAction(action: string) {
    if (action === 'blur') {
      this.startTyping = false;
    }
    if (action === 'focus') {
      this.startTyping = true;
    }
  }

  public navigateToSearch(specialty: string) {
    this.specialtyService.navigateSelected(specialty);
  }

  public navigateToProgram(programNum: number) {
    if(this.router.url.includes('/program/')) {
      window.location.href = "/program/" + programNum;
    } else {
      this.router.navigate(['program', programNum]);
    }
  }

  // a method for returning null instead of an error to keep stream valid
  public handleErrors(res) {
    if (res instanceof Error) {
      return of(null);
    } else {
      return of(res);
    }
  }

  public traverse(num: number) {
    // check that we are getting a valid term from typeahead
    if (this.accSelectionCount >= 0 && this.model.input.length > 2) {
      if ((this.accSelection + num) <= this.accSelectionCount && (this.accSelection + num) > -1) {
        // if we are within the bounds of the accSelection array, add/subtract against the selection
        this.accSelection = this.accSelection + num;
        this.accScroll(num);
      }
    }
  }

  public enterPressed($event: KeyboardEvent): void {
    $event.stopPropagation();
    // if a user has selected an item w/ keyup or keydown

    if (this.accSelection > -1 && this.filterTerm(this.model.input)) {
      const found = this.accMap.filter(program => program.idx === this.accSelection);

      if (found[0]) {
        // if a programID is entered - navigate to program, else add the chip
        this.isProgramId ? this.navigateToProgram(found[0].programNumber) : this.navigateToSearch(found[0]);
      }
    } else {
      if(this.accMap.length > -1) {
        this.navigateToSearch(this.accMap[0])
      }
    }
  }

  private accScroll(num: number): void {
    // if a new search starts, set scroll to 0
    if (this.accSelection === 0 && !this.isProgramId) {
      this.scrollContainer.scrollTo(0, 0);
      this.scrollAmount = 0;
    }
    // if there are 6+ results then we want to scroll, except on the first down key press
    if (this.accSelectionCount > 4 && (num > 0 && this.accSelection !== 0 || num < 0) ) {
      const adjust = num * 30;
      this.scrollAmount += adjust;
      this.scrollContainer.scroll({
        top: this.scrollAmount,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  private resetAccessibility() {
    // reset accessibility context
    this.accSelectionCount = null; this.accSelection = -1; this.accMap = [];
  }
}
