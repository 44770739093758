<div *ngIf="isModalOpen">
  <ama-modal class="freida-modal specialty-modal"
             [show]="isModalOpen"
             (afterClose)="closeSpecialtiesModal()"
             data-test="specialty-modal"
             data-test="specialty-modal">
    <div class="browse-specialties__sub-nav"
         data-test="specialtyModalTab">
      <div class="browse-specialties__sub-nav-items">
        <div class="browse-specialties__sub-nav-item"
             (click)="changeTab('Residencies')"
             [ngClass]="{ 'active-item': selectedTab === 'Residencies' }">Residency</div>
        <div class="browse-specialties__sub-nav-item"
             (click)="changeTab('Fellowships')"
             [ngClass]="{ 'active-item': selectedTab === 'Fellowships' }">Fellowships</div>
      </div>
      <div class="browse-specialties__sub-nav-search">
        <div class="modal-search modal-search--active"
             data-test="specialty-modal-search-button"
             *ngIf="canSearch"
             (click)="onSearchClicked()">Search <ama-icon name="search"
                    size="2em"></ama-icon></div>
        <div class="modal-search modal-search--disabled"
             *ngIf="!canSearch"
             (click)="onSearchClicked()">Search <ama-icon name="search"
                    size="2em"></ama-icon></div>
      </div>
    </div>
    <div class="specialties-container"
         [ngClass]="{ 'hide-section': selectedTab === 'Fellowships' }"
         data-test="residencies-container">
         @for (specialty of residencies; track $index) {
          <ama-checkbox
            [ngClass]="{ 'checkbox-disabled': canSelect }"
            class="specialty-item freida"
            [checked]="specialty.isChecked"
            (checkedChange)="onSpecialtySelected($event, specialty)"
            [labelText]="specialty.name"
            [position]="'right'"
            [themeType]="'secondary'">
          </ama-checkbox>
        }
    </div>

    <div class="specialties-container"
         [ngClass]="{ 'hide-section': selectedTab === 'Residencies' }"
         data-test="fellowships-container">
         @for (specialty of fellowships; track $index) {
          <ama-checkbox
            [ngClass]="{ 'checkbox-disabled': canSelect }"
            class="specialty-item freida"
            [checked]="specialty.isChecked"
            (checkedChange)="onSpecialtySelected($event, specialty)"
            [labelText]="specialty.name"
            [position]="'right'"
            [themeType]="'secondary'">
          </ama-checkbox>
        }
    </div>

    <div class="browse-specialties__sub-nav-search-bottom">
      <div class="modal-search modal-search--active"
           *ngIf="canSearch"
           (click)="onSearchClicked()"
           data-test="modal-search-button">
        Search <ama-icon name="search"
                  size="2em"></ama-icon>
      </div>
      <div class="modal-search modal-search--disabled"
           *ngIf="!canSearch"
           (click)="onSearchClicked()">
        Search <ama-icon name="search"
                  size="2em"></ama-icon>
      </div>
    </div>
  </ama-modal>
</div>